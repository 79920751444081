import { Injectable } from '@angular/core';
import { DistributionAccountModel, ErrorModel } from '../../app.model';
import { ACTION_TYPES } from '../../app.types';
import { AppStore } from '../../store/store';
import { SourceConfigVersionModel } from './config-version.model';
import { SourceConfigModel } from './config.model';

export interface SourceConfigsAction {
  type: string;
  sourceConfig?: SourceConfigModel;
  sourceConfigs?: SourceConfigModel[];
  clientSideAccounts?: DistributionAccountModel[];
  history?: SourceConfigVersionModel[];
  error?: ErrorModel;
}

@Injectable()
export class SourceConfigsActions {

  static dispatchGetSourceConfigs(sourceConfigModels: SourceConfigModel[], appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_CONFIG_GET, sourceConfigs: sourceConfigModels});
  }

  static dispatchCreateSourceConfigSuccessful(appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_CONFIG_SET});
  }

  static dispatchSourceConfigError(errorModel: ErrorModel, appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_CONFIG_ERROR, error: errorModel});
  }

  static dispatchUpdateSourceConfigSuccessful(sourceConfigModel: SourceConfigModel, appStore: AppStore) {
    appStore.dispatch({
      type: ACTION_TYPES.SOURCE_CONFIG_UPDATE,
      sourceConfig: sourceConfigModel,
      sourceConfigUpdated: true
    });
  }

}
