import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AppStore } from '../../store/store';
import { SourceCreateComponent } from '../create/create.component';
import { SourceModel } from '../source.model';
import { sourceSelector } from '../source.reducer';
import { SourceUpdateComponent } from '../update/update.component';

@Component({
  selector: 'app-source-menu',
  templateUrl: 'menu.component.html'
})
export class SourceMenuComponent implements OnInit, OnDestroy {

  unsubscribe;

  createDialogRef: MatDialogRef<SourceCreateComponent>;

  updateDialogRef: MatDialogRef<SourceUpdateComponent>;

  selectedSource: SourceModel = null;

  private matDialogConfig: MatDialogConfig;

  constructor(private dialog: MatDialog,
              private appStore: AppStore) {
  }

  ngOnInit(): void {
    this.matDialogConfig = new MatDialogConfig();
    this.unsubscribe = this.appStore.subscribe((state) => {
      this.selectedSource = sourceSelector(state);
    });
  }

  ngOnDestroy(): void {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  showCreateSourceDialog(): void {
    this.createDialogRef = this.dialog.open(SourceCreateComponent, this.matDialogConfig);
  }

  canEditSource(): boolean {
    return this.selectedSource != null;
  }

  showEditSourceDialog(): void {
    this.updateDialogRef = this.dialog.open(SourceUpdateComponent, this.matDialogConfig);
  }

}
