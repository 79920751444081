export class SourceModel {

  // db id
  id: number;

  // name
  name: string;

  // business id
  sourceId: number;
  crttEnabled: boolean;

  constructor(fields: { id?: number, name?: string, sourceId?: number, crttEnabled?: boolean }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }

  static fromList(objects: any[]): SourceModel[] {
    return objects.map((item) => {
      return SourceModel.from(item);
    });
  }

  static from(sourceObject): SourceModel {
    return new SourceModel(sourceObject);
  }

}
