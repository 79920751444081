import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'orderBy', pure: false})
export class OrderByPipe implements PipeTransform {

  value: string[] = [];

  static _orderByComparator(a: any, b: any): number {

    /* istanbul ignore else */
    if (a === null || typeof a === 'undefined') {
      a = 0;
    }
    /* istanbul ignore else */
    if (b === null || typeof b === 'undefined') {
      b = 0;
    }

    if (a.constructor === Boolean || b.constructor === Boolean) {
      return (a === b) ? 0 : a ? -1 : 1;
    } else if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      const aConv = String(a);
      const bConv = String(b);
      /* istanbul ignore else */
      if (aConv.toLowerCase() < bConv.toLowerCase()) {
        return -1;
      }
      /* istanbul ignore else */
      if (aConv.toLowerCase() > bConv.toLowerCase()) {
        return 1;
      }
    } else {
      /* istanbul ignore else */
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      /* istanbul ignore else */
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }
    return 0;
  }

  static _isDescending(config: string): boolean {
    /* istanbul ignore else */
    if (typeof config === 'undefined' || config === null) {
      return false;
    }
    return config.substr(0, 1) === '-';
  }

  static _getProperty(config: string): string {
    return this._isDescending(config) ? config.substr(1) : config;
  }

  transform(input: any, config: string = '+'): any {
    /* istanbul ignore else */
    if (typeof input === 'undefined' || input === null) {
      return 0;
    }
    const value = [...input];

    const desc = OrderByPipe._isDescending(config);

    if (!config || config === '-' || config === '+') {
      return !desc ? value.sort() : value.sort().reverse();
    } else {
      const property = OrderByPipe._getProperty(config);

      return value.sort((a: any, b: any) => {
        return !desc
          ? OrderByPipe._orderByComparator(a[property], b[property])
          : -OrderByPipe._orderByComparator(a[property], b[property]);
      });
    }
  }
}
