import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { AppStore } from './store/store';
import { UserModel } from './user/user.model';
import { userIsAdminSelector, userSelector } from './user/user.reducer';
import { UserService } from './user/user.service';
import { AuthorizationUtils } from './utils/authorization.utils';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    'app.component.scss'
  ],
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('left') left;

  version: string = environment.version;
  env: string = environment.env;
  isUserSignedIn = false;
  isAdmin$: boolean;
  user$: UserModel;
  unsubscribe;
  dmpUrl: string = environment.dmpUrl;

  constructor(private appStore: AppStore,
              private userService: UserService,
              private router: Router,
              private authService: AuthService,
              private title: Title) {
  }

  ngOnInit() {
    this.title.setTitle(environment.title);
    this.unsubscribe = this.appStore.subscribe((state) => {
      this.user$ = userSelector(state);
      this.isAdmin$ = userIsAdminSelector(state);
      this.isUserSignedIn = this.authService.isUserSignedIn();
    });
  }

  ngOnDestroy() {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  logout() {
    this.left.close();
    this.router.navigateByUrl('/logout')
      .catch((error) => {
        // noop
      });
  }

  canAccessSources(): boolean {
    return AuthorizationUtils.canAccessSources(this.user$);
  }

  canAccessSharedConfigs(): boolean {
    return AuthorizationUtils.canAccessSources(this.user$);
  }
}
