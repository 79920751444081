import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SourceConfigModel } from '../source/config/config.model';

export class CustomValidator {

  static uniqueName(nameList: SourceConfigModel[], currentConfig: SourceConfigModel): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value == null || control.value.length === 0) {
        return null;  // don't validate empty values to allow optional controls
      }
      const value = nameList.filter((config) => {
        return (config.name && config.name === control.value && config.id !== currentConfig.id);
      });
      return value.length > 0 ? {notUnique: {actual: value}} : null;
    };
  }
}
