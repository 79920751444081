import { ACTION_TYPES } from '../app.types';
import { AppStore } from '../store/store';

export class FetchingActions {

  static actionFetchData(appStore: AppStore) {
    /* istanbul ignore else */
    if (appStore) {
      appStore.dispatch({type: ACTION_TYPES.FETCHING_DATA});
    }
  }

}
