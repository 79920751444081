import { Component, OnInit } from '@angular/core';
import { AppStore } from '../../store/store';
import { SharedConfigModel } from '../shared-config.model';
import { sharedConfigListReducer } from '../shared-config.reducer';
import { SharedConfigService } from '../shared-config.service';

@Component({
  selector: 'app-shared-config-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss']
})
export class SharedConfigListComponent implements OnInit {

  sharedConfigList: SharedConfigModel[];

  selectedSharedConfigId: number;

  constructor(private appStore: AppStore, private sharedConfigService: SharedConfigService) {
  }

  ngOnInit(): void {
    this.sharedConfigList = sharedConfigListReducer(this.appStore.getState());
    this.appStore.subscribe((state) => {
      this.sharedConfigList = sharedConfigListReducer(state);
      this.sharedConfigList.sort((a: SharedConfigModel, b: SharedConfigModel) => {
        return b.id - a.id;
      });
    });
    this.sharedConfigService.getSharedConfigs();
  }

  select(event: Event, index: number) {
    event.stopPropagation();
    const sharedConfig: SharedConfigModel = this.getSharedConfigById(index);
    this.selectedSharedConfigId = sharedConfig.id;
  }

  getSharedConfigById(id) {
    return this.sharedConfigList.find((sharedConfig) => {
      return sharedConfig.id === id;
    });
  }
}
