import { NgModule } from '@angular/core';
import { ROUTES } from '../app.routes';
import { SharedModule } from '../modules/shared.module';
import { SharedConfigListComponent } from './list/list.component';
import { SharedConfigActions } from './shared-config.actions';
import { SharedConfigComponent } from './shared-config.component';
import { SharedConfigService } from './shared-config.service';

/** Redux Actions. */
const APP_REDUX_ACTIONS = [
  SharedConfigActions,
  SharedConfigService
];

@NgModule({
  declarations: [
    SharedConfigComponent,
    SharedConfigListComponent
  ],
  imports: [
    SharedModule,
    ROUTES
  ],
  providers: [
    APP_REDUX_ACTIONS
  ],
  bootstrap: [
    SharedConfigComponent
  ]
})
export class SharedConfigModule {
}
