import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { AppStore } from '../store/store';
import { clearNotification } from './notfication.actions';
import { notificationSelector } from './notfication.reducer';

@Component({
  selector: 'app-notification-bar',
  template: ''
})
export class NotificationComponent implements OnInit, OnDestroy {

  snackBarRef: MatSnackBarRef<any>;
  snackBarConfig: MatSnackBarConfig;
  unsubscribe;

  constructor(private snackBar: MatSnackBar,
              private appStore: AppStore) {
  }

  ngOnInit() {
    this.snackBarConfig = new MatSnackBarConfig();
    this.unsubscribe = this.appStore.subscribe((state) => {
      const notification = notificationSelector(state);
      /* istanbul ignore else */
      if (notification) {
        this.notify(notification);
        clearNotification(this.appStore);
      }
    });
  }

  ngOnDestroy() {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  private notify(message: string, timeout = 1000) {
    this.snackBarRef = this.snackBar.open(message, null, this.snackBarConfig);
    // automatic dismiss will be done in a later version
    setTimeout(() => {
      /* istanbul ignore else */
      if (this.snackBar) {
        this.snackBarRef.dismiss();
      }
    }, timeout);
  }

}
