import { ACTION_TYPES } from '../app.types';

export function fetchingContainer(state = {}, action: any) {
  switch (action.type) {
    case ACTION_TYPES.FETCHING_DATA:
      return {...state, isFetching: true};
    default:
      return {...state, isFetching: false};
  }
}

export const isFetchingSelector = (state): boolean => {
  return state.fetchingContainer /* istanbul ignore else */ ? state.fetchingContainer.isFetching : false;
};
