import { ACTION_TYPES } from '../app.types';
import { AuthorizationUtils } from '../utils/authorization.utils';
import { UserAction } from './user.actions';
import { UserModel } from './user.model';

export const INITIAL_USER_STATE = {
  user: {},
  error: '',
  isAdmin: false,
  changedUnauthorized: false,
  userUpdated: false
};

export function userContainer(state = INITIAL_USER_STATE,
                              action: UserAction = {type: '?'}) {

  switch (action.type) {
    case ACTION_TYPES.USER_SET:

      return {
        ...state,
        user: action.user,
        isAdmin: AuthorizationUtils.isAdmin(action.user),
        changedUnauthorized: false,
        userUpdated: true
      };

    case ACTION_TYPES.USER_CLEAR:
      return {...state, user: null, isAdmin: false, userUpdated: false};

    case ACTION_TYPES.USER_ERROR:
      return {...state, error: action.error, userUpdated: false};

    case ACTION_TYPES.USER_RESTORE:
      const user = action.user;

      if (user) {
        return {...state, user, isAdmin: AuthorizationUtils.isAdmin(user), userUpdated: true};
      }
      return {...state, user: null, idAdmin: false, userUpdated: true};

    case ACTION_TYPES.USER_CHANGED_UNAUTHORIZED:
      return {...state, changedUnauthorized: true, userUpdated: false};

    default:
      return {...state, userUpdated: false};
  }
}

export const userUpdatedSelector = (state): boolean => state.userContainer.userUpdated;
export const userSelector = (state): UserModel => state.userContainer.user;
export const userIsAdminSelector = (state): boolean => state.userContainer.isAdmin;
export const userErrorSelector = (state): string => state.userContainer.error;
