import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-sw-update',
  templateUrl: './sw-update.component.html',
  styleUrls: ['./sw-update.component.scss']
})
export class SwUpdateComponent implements OnInit {

  @ViewChild('btn') button: MatButton;

  updateAvailable = false;

  constructor(private updates: SwUpdate, private ngZone: NgZone) {
  }

  ngOnInit(): void {

    /* istanbul ignore next */
    this.updates.activated.subscribe(event => {
      console.log('Old version:', event.previous, 'New version:', event.current);
    });

    /* istanbul ignore next */
    this.updates.available.subscribe(event => {
      console.log('Update available! Current version:', event.current, 'available version:', event.available);
      this.updateAvailable = true;
      setInterval(() => {
        try {
          const rippleConfig = this.button.ripple.rippleConfig;
          rippleConfig.animation.enterDuration = 50;
          rippleConfig.animation.exitDuration = 150;
          rippleConfig.color = '#d2d2d2';
          this.button.ripple.launch(rippleConfig);
        } catch (e) {
          // do nothing
        }
      }, 5000);
    });
  }

  /* istanbul ignore next */
  reload() {
    location.reload();
  }
}
