import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppStore } from '../../store/store';
import { SourceModel } from '../source.model';
import { sourceErrorSelector, sourceSelector, sourceUpdatedSelector } from '../source.reducer';
import { SourceService } from '../source.service';

@Component({
  selector: 'app-source-update',
  templateUrl: 'update.component.html'
})
export class SourceUpdateComponent implements OnInit, OnDestroy {

  original: SourceModel;
  errorMessage: string;
  unsubscribe;
  sourceUpdateForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<SourceUpdateComponent>,
              private appStore: AppStore,
              private sourceService: SourceService) {
  }

  ngOnInit(): void {
    this.original = sourceSelector(this.appStore.getState());

    this.sourceUpdateForm = new FormGroup({
      id: new FormControl(this.original.id),
      name: new FormControl(this.original.name, Validators.required),
      sourceId: new FormControl(this.original.sourceId)
    });

    this.unsubscribe = this.appStore.subscribe((state) => {
      if (sourceUpdatedSelector(state)) {
        this.dialogRef.close();
      } else {
        const sourceUpdateErrorState = sourceErrorSelector(state);
        /* istanbul ignore else */
        if (sourceUpdateErrorState) {
          this.errorMessage = 'Updating new source failed! ' + sourceUpdateErrorState.statusText;
        }
      }
    });
  }

  ngOnDestroy(): void {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  reset() {
    this.resetError();
    this.sourceUpdateForm.reset(this.original);
  }

  update(): void {
    this.resetError();
    this.sourceService.updateSource(SourceModel.from(this.sourceUpdateForm.value));
  }

  private resetError() {
    this.errorMessage = '';
  }
}
