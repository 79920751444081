import { VendorModel } from '../../consent/vendor.model';
import { DistributionAccountModel } from '../../app.model';

export const RENDER_TYPES = {
  JAVASCRIPT: 'JAVASCRIPT',
  IMAGEPIXEL: 'IMAGEPIXEL',
  COOKIE: 'COOKIE' // deprecated
};

export class SourceConfigModel {

  static JAVASCRIPT = 'JAVASCRIPT';
  static IMAGEPIXEL = 'IMAGEPIXEL';
  static COOKIE = 'COOKIE';

  id?: number;
  type?: string;
  name: string;
  description?: string;
  deliverCondition?: string;
  distributionAccountId?: string;
  distributionAccount?: DistributionAccountModel;
  deliverThrottle = 0;
  ignorePeriodDays = 0;
  elementSource?: string;
  fireForKnownUsersOnly?: boolean;
  vendor?: VendorModel;

  constructor(fields: {
    id?: number,
    type: string,
    name: string,
    description?: string,
    deliverCondition?: string,
    distributionAccountId?: string,
    distributionAccount?: DistributionAccountModel,
    deliverThrottle?: number,
    ignorePeriodDays?: number,
    elementSource?: string,
    fireForKnownUsersOnly?: boolean,
    vendor?: VendorModel
  }) {
    if (fields) {
      Object.assign(this, fields);
      if (this.distributionAccount) {
        this.distributionAccount = DistributionAccountModel.from(this.distributionAccount);
      }
    }
  }

  static from(obj): SourceConfigModel {
    return new SourceConfigModel(obj);
  }

  static fromList(list: any[]): SourceConfigModel[] {
    return list.map((it) => {
      return SourceConfigModel.from(it);
    });
  }
}
