import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  public success(msg) {
    this.showMessage(msg, null, 5000);
  }

  public error(msg) {
    this.showMessage(msg, 'close', 15000);
  }

  private showMessage(msg, action: string, durationMs: number) {
    this.snackBar.open(msg, action, {
      duration: durationMs,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
