/** Looks on ALL actions of ANY type for a property 'notification' and assign it to the state. */
export function notificationContainer(state = {}, action: any) {
  if (action.notification !== undefined) {
    return {...state, notification: action.notification};
  }
  return state;
}

/** selects notification from state. */
export const notificationSelector = (state) => {
  return state.notificationContainer ? state.notificationContainer.notification : null;
};
