import { AppStore } from '../store/store';

/** No action class. */
export function notify(appStore: AppStore, message: string) {
  if (message !== null && message.length > 0) {
    appStore.dispatch({type: 'notification', notification: message});
  }
}

export function clearNotification(appStore: AppStore) {
  appStore.getState().notificationContainer = {};
}

