import { UserModel } from '../user/user.model';

const ADMIN_ROLE_NAME = 'admin';
const PARTNER_ROLE_NAME = 'partner';

export class AuthorizationUtils {

  static PARTNER_ROLE_NAME = PARTNER_ROLE_NAME;

  static isAdmin(user: any): boolean {
    return AuthorizationUtils.hasRole(ADMIN_ROLE_NAME, user);
  }

  static isPartner(user: any): boolean {
    return AuthorizationUtils.hasRole(PARTNER_ROLE_NAME, user);
  }

  static hasRole(role: string, user: any): boolean {
    if (!user || !user.roles) {
      return false;
    }

    return user.roles.filter((r) => {
      return `${r}`.toLowerCase() === role;
    }).length > 0;
  }

  static canAccessSources(user: UserModel) {
    return AuthorizationUtils.isAdmin(user);
  }

  static canAccessDistributionAccounts(user: UserModel) {
    return AuthorizationUtils.isAdmin(user);
  }

  static canAccessTheTradeDeskPartnerConfig(user: UserModel) {
    return AuthorizationUtils.isAdmin(user);
  }
}
