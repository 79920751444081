import { AbstractControl, ValidatorFn } from '@angular/forms';

const esprima = require('esprima');

export class CodeChecker {

  static code(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const syntaxError = CodeChecker.check(control.value || '');
      return syntaxError !== null ? {syntax: syntaxError} : null;
    };
  }

  static check(code: string): string {
    try {
      esprima.parseScript(code);
      return null;
    } catch (e) {
      return JSON.stringify(e);
    }
  }
}
