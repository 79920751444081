export class UserModel {
  username: string;
  partnerId: number;
  roles: string[] = [];

  session?: any;

  constructor(fields: { username: string, partnerId?: number, roles?: string[] }) {
    /* istanbul ignore else */
    if (fields) {
      Object.assign(this, fields);
    }
  }

}
