import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ACTION_TYPES} from '../app.types';
import {AuthService} from '../auth/auth.service';
import {AppStore} from '../store/store';
import {AuthorizationUtils} from '../utils/authorization.utils';
import {UserActions} from './user.actions';
import {UserModel} from './user.model';

export interface UserAction {
  type: string;
  user?: UserModel;
  error?: string;
}

@Injectable()
export class UserService {

  constructor(public appStore: AppStore,
              private router: Router,
              private httpClient: HttpClient,
              private auth: AuthService) {
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.appStore.dispatch({type: ACTION_TYPES.USER_CLEAR});
    this.auth.signOut();
  }

  checkCredentials(): Observable<boolean> {

    return new Observable((subscriber) => {

      this.auth.session({

        onSuccess: (session) => {
          const user = UserActions.userGetLocalStorage();
          user.username = (session.getIdToken().decodePayload() as any).email || 'user not set';
          user.roles = session.getIdToken().decodePayload()['cognito:groups'] || [];

          this.appStore.dispatch(this.userSet(user));

          subscriber.next(true);
          subscriber.complete();

        },
        onFailure: (err) => {
          console.log('Auth error', err);
          subscriber.next(false);
          this.auth.signOut();
        }
      });
    });
  }

  /**
   * Sets the user into local store and return a action.
   * Route to partner selection or segments.
   *
   * @param user user
   */
  userSet(user: UserModel) {
    UserActions.userSetLocalStorage(user);
    const appUser = new UserModel(user);
    return {type: ACTION_TYPES.USER_SET, user: appUser};
  }

  routeUser(user: UserModel) {
    if (AuthorizationUtils.isAdmin(user) && !user.partnerId) {
      this.router.navigate(['/partner']);
    } else {
      this.router.navigate(['segments']);
    }
  }

  changePartner(user: UserModel) {
    UserActions.userSetLocalStorage(user);
    this.appStore.dispatch({type: ACTION_TYPES.USER_SET, user: new UserModel(user)});
  }
}
