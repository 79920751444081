import { Injectable } from '@angular/core';
import { CognitoAuth, CognitoAuthOptions, CognitoAuthUserHandler } from 'amazon-cognito-auth-js';
import { environment } from '../../environments/environment';

/**
 * see: https://github.com/aws/amazon-cognito-auth-js
 * see: https://www.npmjs.com/package/@types/amazon-cognito-auth-js
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  auth = new CognitoAuth(environment.auth as CognitoAuthOptions);

  constructor() {
    this.auth.useCodeGrantFlow();
  }

  // use this for refresh token
  public signIn(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.signInPromise(resolve, reject);
    });
  }

  isUserSignedIn() {
    return this.auth.isUserSignedIn();
  }

  signInPromise(resolve, reject) {
    this.auth.userhandler = {
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        console.log('SignIn err', err);
        reject();
      }
    };
    this.auth.parseCognitoWebResponse(window.location.href);
  }

  public session(userHandler: CognitoAuthUserHandler): void {
    // TODO 06.11.19: make userHandler do not know cognito
    this.auth.userhandler = userHandler;

    this.auth.getSession();
  }

  public signOut(): void {
    console.log('Sign out ...');
    this.auth.signOut();
  }

}
