import { SourceConfigModel } from './config.model';

export class SourceConfigVersionModel {

  id: number;
  validFrom: Date;
  sourceConfigs: SourceConfigModel[];

  constructor(fields: {
    id: number,
    validFrom: Date,
    sourceConfigs: SourceConfigModel[]
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }

  static from(obj): SourceConfigVersionModel {
    const sourceConfigsVersionModel = new SourceConfigVersionModel(obj);
    const validFrom = sourceConfigsVersionModel.validFrom;
    if (validFrom.constructor.name !== 'Date') {
      sourceConfigsVersionModel.validFrom = new Date(validFrom);
    }

    sourceConfigsVersionModel.sourceConfigs = SourceConfigModel.fromList(obj.sourceConfigs);

    return sourceConfigsVersionModel;
  }

  static fromList(objects: any[]): SourceConfigVersionModel[] {
    return objects.map((item) => {
      SourceConfigModel.fromList(item.sourceConfigs);
      return SourceConfigVersionModel.from(item);
    });
  }

}
