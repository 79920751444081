import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.auth.signIn()
      .then(() => {
        this.router.navigateByUrl('/');
      })
      .catch((error) => {
        /* istanbul ignore next */
        console.log('signin', error);
      });
  }

}
