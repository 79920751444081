import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import 'prismjs/prism';

declare let Prism: any;

@Component({
  selector: 'app-formatted-code',
  styleUrls: ['formatted-code.component.scss'],
  template: `
    <div hidden="true" #rawContent>
      <ng-content></ng-content>
    </div>
    <table>
      <tr>
        <td>
          <pre><code #formattedCode></code></pre>
        </td>
      </tr>
    </table>
  `
})
export class FormattedCodeComponent implements AfterViewInit {
  @Input() language = 'javascript';
  @Input() code = '';
  @Input() folded = false;

  @ViewChild('formattedCode') private formattedCode: ElementRef;

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    /* istanbul ignore else */
    if (this.folded) {
      this.elementRef.nativeElement.classList.add('foldable');
    }
    this.formattedCode.nativeElement.innerHTML = Prism.highlight(this.code || '', Prism.languages[this.language]).trim();
  }
}
