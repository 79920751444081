import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ACTION_TYPES } from '../../app.types';
import { notify } from '../../notification/notfication.actions';
import { AppStore } from '../../store/store';
import { HttpClientUtils } from '../../utils/http-client.utils';
import { HttpInteraction } from '../../utils/http-interactions.utils';
import { SourceModel } from '../source.model';
import { SourceConfigVersionModel } from './config-version.model';
import { SourceConfigsActions } from './config.actions';
import { SourceConfigModel } from './config.model';
import { DistributionAccountModel } from '../../app.model';

@Injectable()
export class SourceConfigService {

  constructor(public appStore: AppStore, private httpClientUtils: HttpClientUtils) {
  }

  /** NO BFF */
  getConfigsForSource(sourceId: number) {
    this.httpClientUtils.getForJson(`${environment.apiSourceConfigs}/sources/${sourceId}/configs`)
      .onSuccess((response) => {
        const sourceConfigModels = SourceConfigModel.fromList(response.data || response || []);
        SourceConfigsActions.dispatchGetSourceConfigs(sourceConfigModels, this.appStore);
      })
      .onError((error) => {
        this.appStore.dispatch({
          type: ACTION_TYPES.SOURCE_CONFIG_ERROR,
          error: HttpInteraction.errorModel(error)
        });
      })
      .doIt();
  }

  /** NO BFF */
  createConfigForSource(sourceId: number, sourceConfig: SourceConfigModel) {
    this.httpClientUtils.post(`${environment.apiSourceConfigs}/sources/${sourceId}/configs`, sourceConfig)
      .onSuccess(() => {
        notify(this.appStore, `Source Config with ID ${sourceConfig.id} created.`);
        SourceConfigsActions.dispatchCreateSourceConfigSuccessful(this.appStore);
        this.getConfigsForSource(sourceId);
      })
      .onError((error) => {
        SourceConfigsActions.dispatchSourceConfigError(HttpInteraction.errorModel(error), this.appStore);
      })
      .doIt();
  }

  /** NO BFF */
  updateConfigForSource(sourceId: number, sourceConfigId: number, sourceConfigModel: SourceConfigModel) {
    this.httpClientUtils.put(`${environment.apiSourceConfigs}/sources/${sourceId}/configs/${sourceConfigId}`,
      sourceConfigModel)
      .onSuccess(() => {
        notify(this.appStore, `Source Config with ID ${sourceConfigModel.id} updated.`);
        SourceConfigsActions.dispatchUpdateSourceConfigSuccessful(sourceConfigModel, this.appStore);
        this.getConfigsForSource(sourceId);
      })
      .onError((error) => {
        SourceConfigsActions.dispatchSourceConfigError(HttpInteraction.errorModel(error), this.appStore);
      })
      .doIt();
  }

  /** NO BFF */
  deleteSourceConfig(source: SourceModel, sourceConfigModel: SourceConfigModel) {
    this.httpClientUtils.delete(`${environment.apiSourceConfigs}/sources/${source.id}/configs/${sourceConfigModel.id}`)
      .onSuccess(() => {
        this.appStore.dispatch({type: ACTION_TYPES.SOURCE_CONFIG_DELETE, sourceConfig: sourceConfigModel});
        notify(this.appStore, 'SourceConfig ' + sourceConfigModel.id + ' deleted');
        this.getConfigsForSource(source.id);
      })
      .onError((error) => {
        this.appStore.dispatch({
          type: ACTION_TYPES.SOURCE_CONFIG_ERROR,
          error: HttpInteraction.errorModel(error)
        });
      })
      .doIt();
  }

  /** NO BFF */
  getClientSideAccounts() {
    this.httpClientUtils.getForJson(`${environment.apiSegments}/clientSideAccounts`)
      .onSuccess((response) => {
        const accounts = DistributionAccountModel.fromList(response.data || response as DistributionAccountModel[]);
        this.appStore.dispatch({
          type: ACTION_TYPES.SOURCE_CONFIG_GET_CLIENT_SIDE_ACCOUNTS,
          clientSideAccounts: accounts
        });
      })
      .onError((error) => {
        this.appStore.dispatch({type: ACTION_TYPES.SOURCE_CONFIG_ERROR, error: HttpInteraction.errorModel(error)});
      })
      .doIt();
  }

  /** NO BFF */
  getConfigHistoryForSource(sourceId: number) {
    this.httpClientUtils.getForJson(`${environment.apiSourceConfigs}/sources/${sourceId}/configs/history`)
      .onSuccess((response) => {
        const configsHistory = SourceConfigVersionModel.fromList(response.data || response as SourceConfigVersionModel[]);
        this.appStore.dispatch({
          type: ACTION_TYPES.SOURCE_CONFIG_SET_HISTORY,
          history: configsHistory
        });
      })
      .onError((error) => {
        this.appStore.dispatch({
          type: ACTION_TYPES.SOURCE_CONFIG_ERROR,
          error: HttpInteraction.errorModel(error)
        });
      })
      .doIt();
  }
}
