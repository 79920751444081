import { Component } from '@angular/core';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent {
  constructor(private userService: UserService) {
    this.userService.logout();
  }
}
