import { Component, OnDestroy, OnInit } from '@angular/core';
import { isFetchingSelector } from '../fetching/fetching.reducer';
import { AppStore } from '../store/store';

@Component({
  selector: 'app-progress',
  template: `
    <mat-progress-bar mode="{{mode}}"></mat-progress-bar>`
})
export class ProgressComponent implements OnInit, OnDestroy {

  mode = 'none';

  private unsubscribe;

  constructor(private appStore: AppStore) {
  }

  ngOnInit() {
    this.unsubscribe = this.appStore.subscribe((state) => {
      this.mode = isFetchingSelector(state) ? 'indeterminate' : 'none';
    });
  }

  ngOnDestroy() {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

}
