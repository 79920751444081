import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../modules/shared.module';
import { DocumentationComponent } from './documentation.component';

@NgModule({
  declarations: [DocumentationComponent],
  imports: [
    CommonModule,
    MatIconModule,
    SharedModule
  ]
})
export class DocumentationModule {
}
