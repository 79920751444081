import {NgModule} from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';
import 'codemirror/mode/javascript/javascript';
import {MatCodemirrorModule} from 'ngx-mat-codemirror';
import {ROUTES} from '../app.routes';
import {SharedModule} from '../modules/shared.module';
import {SourceConfigService} from './config/config.service';
import {SourceConfigDeleteComponent} from './config/delete/delete.component';
import {SourceConfigEditOneFormComponent} from './config/edit-one-form/edit-one-form.component';
import {SourceConfigHistoryComponent} from './config/history/history.component';
import {SourceConfigsHistorySelectComponent} from './config/history/select/select.component';
import {SourceConfigsListComponent} from './config/list/list.component';
import {SourceCreateComponent} from './create/create.component';
import {SourceMenuComponent} from './menu/menu.component';
import {SourceSelectComponent} from './select/select.component';
import {SourceActions} from './source.actions';
import {SourceComponent} from './source.component';
import {SourceService} from './source.service';
import {SourceUpdateComponent} from './update/update.component';
import {CrttConfigComponent} from './crtt/crtt-config.component';

/** Redux Actions. */
const APP_REDUX_ACTIONS = [
  SourceActions,
  SourceService,
  SourceConfigService,
  SourceConfigService
];

@NgModule({
  declarations: [
    SourceComponent,
    SourceUpdateComponent,
    SourceCreateComponent,
    SourceMenuComponent,
    SourceSelectComponent,
    SourceConfigsListComponent,
    SourceConfigHistoryComponent,
    SourceConfigsHistorySelectComponent,
    SourceConfigDeleteComponent,
    SourceConfigEditOneFormComponent,
    CrttConfigComponent
  ],
  imports: [
    SharedModule,
    MatCodemirrorModule,
    MatStepperModule,
    ROUTES
  ],
  providers: [
    APP_REDUX_ACTIONS
  ],
  entryComponents: [
    SourceConfigEditOneFormComponent, SourceConfigDeleteComponent
  ],
  bootstrap: [
    SourceComponent
  ]
})
export class SourceModule {
}
