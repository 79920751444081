import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { VendorModel } from '../../../consent/vendor.model';
import { VendorService } from '../../../consent/vendor.service';
import { AppStore } from '../../../store/store';
import { CustomValidator } from '../../../utils/custom.validator';
import { SourceModel } from '../../source.model';
import { sourceSelector } from '../../source.reducer';
import { RENDER_TYPES, SourceConfigModel } from '../config.model';
import {
  sourceConfigClientSideAccountsSelector,
  sourceConfigCreatedSelector,
  sourceConfigErrorSelector,
  sourceConfigListSelector,
  sourceConfigUpdatedSelector
} from '../config.reducer';
import { SourceConfigService } from '../config.service';
import { CodeChecker } from './CodeChecker';

import { DistributionAccountModel } from '../../../app.model';

@Component({
  selector: 'app-edit-one-form',
  templateUrl: './edit-one-form.component.html',
  styleUrls: ['./edit-one-form.component.scss']
})
export class SourceConfigEditOneFormComponent implements OnInit, OnDestroy {

  maxLengthDeliverCondition = 15000;
  maxLengthElementSource = 10000;
  maxLengthDescription = 1000;
  maxLengthName = 300;

  configForm: FormGroup;
  model: SourceConfigModel;
  currentSource: SourceModel;
  currentSourceConfig: SourceConfigModel;
  sourceConfigs: SourceConfigModel[];
  distributionAccounts: DistributionAccountModel[] = [];

  // data model of all existing vendors
  vendors: VendorModel[] = [];

  // currently filtered (by typing) vendors
  filteredVendors: Observable<VendorModel[]>;

  configTypes = [RENDER_TYPES.IMAGEPIXEL, RENDER_TYPES.JAVASCRIPT];

  mode = 'Add';

  errorMessage: string;

  unsubscribe;

  static setClientSideDistributionAccounts(appState: any) {
    const clientSideAccountsWithId = sourceConfigClientSideAccountsSelector(appState).filter((accountModel: DistributionAccountModel) => {
      return !(accountModel.foreignAccountId === null || accountModel.foreignAccountId === undefined);
    });
    return [new DistributionAccountModel({
      id: 0,
      description: '-- none --'
    })].concat(clientSideAccountsWithId);
  }

  constructor(private dialogRef: MatDialogRef<SourceConfigEditOneFormComponent>,
              private appStore: AppStore,
              private sourceConfigsService: SourceConfigService,
              private vendorService: VendorService) {
  }

  ngOnInit() {
    const appState = this.appStore.getState();

    this.currentSource = sourceSelector(appState);
    this.sourceConfigs = sourceConfigListSelector(appState);

    this.distributionAccounts = SourceConfigEditOneFormComponent.setClientSideDistributionAccounts(appState);

    if (this.currentSourceConfig) {
      this.mode = 'Edit';
      this.model = SourceConfigModel.from(this.currentSourceConfig);
    } else {
      this.model = new SourceConfigModel({name: '', type: RENDER_TYPES.IMAGEPIXEL});
    }

    this.initFormGroup();

    this.unsubscribe = this.appStore.subscribe((state) => {
      /* istanbul ignore else */
      if (sourceConfigCreatedSelector(state) || sourceConfigUpdatedSelector(state)) {
        this.dialogRef.close();
      }
      const errorSelector = sourceConfigErrorSelector(state);
      /* istanbul ignore else */
      if (errorSelector) {
        this.errorMessage = errorSelector.statusText;
      }
    });

    this.initVendors();

  }

  initVendors() {
    this.vendorService.getVendors().subscribe((vendors) => {
      this.vendors = vendors;
      this.filteredVendors = this.configForm.get('vendor')
        .valueChanges
        .pipe(
          startWith(''),
          map(value => value === null ? '' : value),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this.filterVendors(name) : this.vendors.slice())
        );
    });
  }

  ngOnDestroy(): void {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  submit() {
    this.errorMessage = '';

    this.patchEmptyVendor();

    if (this.currentSourceConfig) {
      this.sourceConfigsService.updateConfigForSource(
        this.currentSource.id,
        this.currentSourceConfig.id,
        new SourceConfigModel({...this.configForm.value})
      );
    } else {
      this.sourceConfigsService
        .createConfigForSource(this.currentSource.id, new SourceConfigModel({...this.configForm.value}));
    }
  }

  private patchEmptyVendor() {
    const vendor = this.configForm.get('vendor');
    if (vendor.value === '') {
      vendor.patchValue(null);
    }
  }

  get vendor() {
    return this.configForm.get('vendor');
  }

  initFormGroup() {
    this.configForm = new FormGroup({

      distributionAccountId: new FormControl(this.model.distributionAccountId),

      name: new FormControl(this.model.name,
        [
          Validators.required,
          CustomValidator.uniqueName(this.sourceConfigs, this.model),
          Validators.maxLength(this.maxLengthName)
        ]
      ),

      description: new FormControl(this.model.description,
        [
          Validators.maxLength(this.maxLengthDescription)
        ]
      ),

      type: new FormControl(this.model.type,
        [
          Validators.required
        ]
      ),

      vendor: new FormControl(this.model.vendor),

      deliverCondition: new FormControl(this.model.deliverCondition,
        [
          Validators.maxLength(this.maxLengthDeliverCondition),
          CodeChecker.code()
        ]
      ),

      elementSource: new FormControl(this.model.elementSource,
        [
          Validators.required,
          Validators.maxLength(this.maxLengthElementSource),
          CodeChecker.code()
        ]
      ),

      deliverThrottle: new FormControl(this.model.deliverThrottle,
        [
          Validators.max(1),
          Validators.min(0),
          Validators.pattern('^([0-9]*[.])?[0-9]+$')
        ]
      ),

      fireForKnownUsersOnly: new FormControl(this.model.fireForKnownUsersOnly),

      ignorePeriodDays: new FormControl(this.model.ignorePeriodDays,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$')
        ]
      )

    });

    this.initVendorValidators();
  }

  initVendorValidators() {
    const vendor = this.configForm.get('vendor');
    const type = this.configForm.get('type');

    if (this.model.type === SourceConfigModel.IMAGEPIXEL || this.isJavascriptWithSync()) {
      vendor.setValidators(Validators.required);
      vendor.updateValueAndValidity();
    } else {
      vendor.clearValidators();
      vendor.updateValueAndValidity({emitEvent: false});
    }

    // TODO 03.08.20: emit events?
    this.configForm.valueChanges.subscribe(() => {
      if (type.value === SourceConfigModel.IMAGEPIXEL || this.isJavascriptWithSync()) {
        vendor.setValidators(Validators.required);
        vendor.updateValueAndValidity({emitEvent: false});
      } else {
        vendor.clearValidators();
        vendor.updateValueAndValidity({emitEvent: false});
      }
    });
  }

  hasInvalidFields() {
    return this.configForm.get('distributionAccountId').invalid
      || this.configForm.get('name').invalid
      || this.configForm.get('description').invalid
      || this.configForm.get('type').invalid
      || this.configForm.get('deliverCondition').invalid
      || this.configForm.get('elementSource').invalid
      || this.configForm.get('deliverThrottle').invalid
      || this.configForm.get('fireForKnownUsersOnly').invalid
      || this.configForm.get('ignorePeriodDays').invalid
      || this.configForm.get('vendor').invalid;
  }

  isJavascriptWithSync() {
    const type = this.configForm.get('type').value;
    const code = (this.configForm.get('elementSource').value || '');
    return type === SourceConfigModel.JAVASCRIPT && (
      code.match(/new\s+Image\(/) !== null ||
      code.includes('document.createElement(') ||
      code.includes('fetch(')
    );
  }

  filterVendors(value: string): VendorModel[] {
    const filterValue = value.toLowerCase();
    return this.vendors.filter(v => v !== null && v && v.name.toLowerCase().includes(filterValue));
  }

  displayVendor(vendor: VendorModel): string {
    return (vendor !== null && vendor) && vendor.name ? (`${vendor.vendorId} - ${vendor.name}`) : '';
  }

}
