import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStore } from '../../../store/store';
import { SourceComponent } from '../../source.component';
import { SourceModel } from '../../source.model';
import { sourceSelector } from '../../source.reducer';
import { SourceConfigVersionModel } from '../config-version.model';
import { sourceConfigHistorySelector } from '../config.reducer';
import { SourceConfigService } from '../config.service';

@Component({
  selector: 'app-source-configs-history',
  templateUrl: 'history.component.html',
  styleUrls: ['history.component.scss']
})
export class SourceConfigHistoryComponent implements OnInit {
  // history:SourceConfigVersionModel[] = [];
  currentHistoryVersion: SourceConfigVersionModel;
  activeVersion: SourceConfigVersionModel;
  inactiveVersions: SourceConfigVersionModel[] = [];

  dateTimeFormat = SourceComponent.dateTimeFormat;

  private currentHistoryVersionIndex = 0;


  constructor(private appStore: AppStore, private sourceConfigsService: SourceConfigService, private router: Router) {

  }

  ngOnInit(): void {
    const currentSource: SourceModel = sourceSelector(this.appStore.getState());
    /* istanbul ignore else */
    if (currentSource === null) {
      // TODO fix navigation soon and use constants for paths
      this.router.navigateByUrl('/sources');
      return;
    }

    this.appStore.subscribe((state) => {
      this.configureVersions(sourceConfigHistorySelector(state));
    });

    this.sourceConfigsService.getConfigHistoryForSource(currentSource.id);
  }


  getValidFromCurrentHistory(): Date {
    return this.inactiveVersions[this.currentHistoryVersionIndex].validFrom;
  }


  getValidToCurrentHistory(): Date {
    /* istanbul ignore else */
    if (this.currentHistoryVersionIndex === 0) {
      return this.getValidFromActiveVersion();
    }
    return this.inactiveVersions[this.currentHistoryVersionIndex - 1].validFrom;
  }

  getValidFromActiveVersion(): Date {
    return this.activeVersion.validFrom;
  }


  select(newIndex) {
    this.currentHistoryVersionIndex = newIndex;
    this.currentHistoryVersion = this.inactiveVersions[newIndex];
  }

  private configureVersions(history: SourceConfigVersionModel[]) {

    history.sort((a, b) => {
      return b.id - a.id;
    });

    this.inactiveVersions = history.map((item) => {
      return new SourceConfigVersionModel(item);
    });

    this.activeVersion = this.inactiveVersions.shift();
    this.currentHistoryVersion = this.inactiveVersions[this.currentHistoryVersionIndex];
  }
}
