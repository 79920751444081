import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {

  readonly deliverPeriodCode = `
    // validTime(year, month, date, hour, periodInHours)
    validTime(2016, 2, 25, 11, 1)

    // defines a start time and period for time validation
    def validTime(int year, int month, int day, int hour, int periodInHours) {
      def start = Calendar.instance; start.set(year, month -1, day, hour, 0, 0);
      start. setTimeZone(TimeZone.getTimeZone("Europe/Berlin"));
      def end = start.clone()
      end.add(Calendar.HOUR, periodInHours)
      def now = Calendar.instance
    return (start.before(now) && end.after(now))
    }
  `;
  isHostSiteContainedCode = ` def domains = ['test.com','foo.bar.com','sub.domain.de']
  isHostSiteContainedIn(domains)`;
  isHostSiteContainedCode2 = `  isHostSiteContainedIn(['test.com','foo.bar.com','sub.domain.de'])`;


  constructor() { }

  ngOnInit() {
  }

}
