import { Injectable } from '@angular/core';
import { ErrorModel } from '../app.model';
import { ACTION_TYPES } from '../app.types';
import { AppStore } from '../store/store';
import { SourceModel } from './source.model';

export interface SourcesAction {
  type: string;
  source?: SourceModel;
  sources?: SourceModel[];
  error?: ErrorModel;
}

@Injectable()
export class SourceActions {

  // use this in tests...
  static dispatchGetAll(appStore: AppStore, sourcesModels: SourceModel[]) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_LIST_GET, sources: sourcesModels});
  }

  // use this in tests...
  static dispatchCreateSourceError(errorModel, appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_ERROR, error: errorModel});
  }

  // use this in tests...
  static dispatchCreateSource(appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_SET});
  }

  // use this in tests...
  static dispatchUpdateSourceError(errorModel, appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_ERROR, error: errorModel});
  }

  // use this in tests...
  static dispatchUpdateSource(appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_UPDATE});
  }

  // use this in tests...
  static dispatchSelectSource(sourceModel: SourceModel, appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SOURCE_SELECTED, source: sourceModel});
  }

}
