import { Component } from '@angular/core';
import { AppStore } from '../store/store';
import { UserModel } from '../user/user.model';
import { userSelector } from '../user/user.reducer';
import { AuthorizationUtils } from '../utils/authorization.utils';

@Component({
  selector: 'app-home',
  styleUrls: ['home.component.scss'],
  templateUrl: 'home.component.html'
})
export class HomeComponent {
  user$: UserModel;

  constructor(private appStore: AppStore) {
    const appState = this.appStore.getState();
    this.user$ = userSelector(appState);
  }

  canAccessSources(): boolean {
    return AuthorizationUtils.canAccessSources(this.user$);
  }

  canAccessSharedConfigs(): boolean {
    return AuthorizationUtils.canAccessSources(this.user$);
  }

  canAccessDistributionAccounts(): boolean {
    return AuthorizationUtils.canAccessDistributionAccounts(this.user$);
  }
}
