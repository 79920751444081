import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AppStore } from '../../store/store';
import { SourceConfigService } from '../config/config.service';
import { SourceModel } from '../source.model';
import { sourceListSelector, sourceSelector } from '../source.reducer';
import { SourceService } from '../source.service';

@Component({
  selector: 'app-source-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.component.scss']
})
export class SourceSelectComponent implements OnInit, OnDestroy {
  sourcesControl: FormControl = new FormControl();

  filteredSources: SourceModel[];

  allSources: SourceModel[] = [];

  unsubscribe;
  sourcesControlSubscription;

  selectedSource: SourceModel = null;

  constructor(private appStore: AppStore,
              private sourceService: SourceService,
              private sourceConfigsService: SourceConfigService) {
  }

  ngOnInit() {
    this.unsubscribe = this.appStore.subscribe((state) => {
      this.allSources = sourceListSelector(state) || [];
      this.filteredSources = this.allSources;
      this.selectedSource = sourceSelector(state);

      this.sourcesControl.setValue(sourceSelector(state));
    });

    this.sourceService.getAllSources();

    this.sourcesControlSubscription = this.sourcesControl.valueChanges
      .pipe(
        startWith(''),
        map(source => {
          return source ? this._filterSources(source) : this.allSources.slice();
        })
      )
      .subscribe((filteredSources) => {
        this.filteredSources = filteredSources;
      });

    this.sourceConfigsService.getClientSideAccounts();
  }

  ngOnDestroy(): void {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    /* istanbul ignore else */
    if (this.sourcesControlSubscription) {
      this.sourcesControlSubscription.unsubscribe();
    }
  }

  displayFn(source): string {
    return source ? source.sourceId + ' - ' + source.name : undefined;
  }

  canEditSource(): boolean {
    return this.selectedSource != null;
  }

  sourceSelected() {
    const source = this.sourcesControl.value;
    /* istanbul ignore else */
    if (source instanceof SourceModel) {
      this.sourceService.selectSource(source);
      this.sourceConfigsService.getConfigsForSource(source.id);
      this.sourceConfigsService.getConfigHistoryForSource(source.id);
    }
  }

  private _filterSources(value: string): SourceModel[] {
    return this.allSources.filter((option) => {
      return new RegExp(value, 'gi').test(option.name + ' ' + option.sourceId);
    });
  }
}
