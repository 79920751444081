import * as process from 'process';

export const environment = {
  production: false,
  env: process.env.ENV = process.env.NODE_ENV = 'development',
  apiSourceConfigs: 'https://dev.sources.dmp.emetriq.de',
  apiSegments: 'https://dev.segments.dmp.emetriq.de',
  dmpUrl: 'https://dev.dmp.emetriq.de',
  host: process.env.HOST || 'localhost',
  port: parseInt(process.env.PORT, 10) || 8080,
  title: 'DEV Profiling Source Configs',
  animation: true,
  version: 'a5121000',
  auth: {
    ClientId: '1dc4cm9r0bku7vqeghsa5hm5s8',
    AppWebDomain: 'emq-auth.auth.eu-west-1.amazoncognito.com',
    TokenScopesArray: ['email', 'openid'],
    RedirectUriSignIn: 'https://dev.source-config.dmp.emetriq.de/signin',
    RedirectUriSignOut: 'https://dev.source-config.dmp.emetriq.de/',
    UserPoolId: 'eu-west-1_maI6P4tPq', // xsite prod :: cognito :: emq-platform-dev
    AdvancedSecurityDataCollectionFlag: true
  }
};

export const HTTP_INTERCEPORTS = [];
