import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { DistributionAccountModel } from '../../../app.model';
import { AppStore } from '../../../store/store';
import { SourceConfigModel } from '../config.model';
import { sourceConfigClientSideAccountsSelector, sourceConfigListSelector } from '../config.reducer';
import { SourceConfigDeleteComponent } from '../delete/delete.component';
import { SourceConfigEditOneFormComponent } from '../edit-one-form/edit-one-form.component';

@Component({
  selector: 'app-source-configs-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss']
})
export class SourceConfigsListComponent implements OnInit {

  sourceConfigs: SourceConfigModel[];

  selectedSourceConfigId: number;

  dialogRef: MatDialogRef<SourceConfigEditOneFormComponent>;

  dialogEditSource: MatDialogRef<SourceConfigEditOneFormComponent>;

  deleteDialog: MatDialogRef<SourceConfigDeleteComponent>;

  constructor(private appStore: AppStore, private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    // try to get directly from store
    this.sourceConfigs = sourceConfigListSelector(this.appStore.getState());

    this.appStore.subscribe((state) => {
      this.sourceConfigs = sourceConfigListSelector(state);
      this.sortById();
      this.mergeAccounts(this.sourceConfigs, state);
    });
  }

  mergeAccounts(configs: SourceConfigModel[] = [], state: any = {}): void {
    const accounts = sourceConfigClientSideAccountsSelector(state) as DistributionAccountModel[] || [];
    configs.forEach((config) => {
      const filtered = accounts.filter((it) => it.foreignAccountId === config.distributionAccountId);
      if (filtered.length > 0) {
        config.distributionAccount = filtered[0];
      }
    });
  }

  private sortById() {
    this.sourceConfigs.sort((a: SourceConfigModel, b: SourceConfigModel) => b.id - a.id);
  }

  display(): boolean {
    const source = this.appStore.getState().sourceContainer.source;
    return (source && source.id > 0);
  }

  select(event: Event, index: number) {
    event.stopPropagation();
    const sourceConfig: SourceConfigModel = this.getSourceConfigById(index);
    this.selectedSourceConfigId = sourceConfig.id;
  }

  getSourceConfigById(id) {
    return this.sourceConfigs.find((sourceConfig) => {
      return sourceConfig.id === id;
    });
  }

  showSourceConfigDialog() {
    this.dialogEditSource = this.matDialog.open(SourceConfigEditOneFormComponent, new MatDialogConfig());
  }

  showEditSourceConfigDialog() {
    /* istanbul ignore else */
    if (this.selectedSourceConfigId > 0) {
      // TODO: change data transfer
      this.dialogEditSource = this.matDialog.open(SourceConfigEditOneFormComponent, new MatDialogConfig());
      this.dialogEditSource.componentInstance.currentSourceConfig = this.getSourceConfigById(this.selectedSourceConfigId);
    }
  }

  showDeleteSourceConfigDialog() {
    /* istanbul ignore else */
    if (this.selectedSourceConfigId > 0) {
      this.deleteDialog = this.matDialog.open(SourceConfigDeleteComponent, new MatDialogConfig());
      this.deleteDialog.componentInstance.currentSourceConfig = this.getSourceConfigById(this.selectedSourceConfigId);
    }
  }
}
