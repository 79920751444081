import { ACTION_TYPES } from '../../app.types';
import { SourceConfigsAction } from './config.actions';
import { SourceConfigModel } from './config.model';

const DEFAULT_FLAGS = {
  error: null,
  sourceConfigCreated: false,
  sourceConfigUpdated: false,
  sourceConfigDeleted: false
};

export const INITIAL_STATE = {
  sourceConfigs: [],
  clientSideAccounts: [],
  history: [],
  ...DEFAULT_FLAGS
};

export function sourceConfigurationsContainer(state = INITIAL_STATE,
                                              action: SourceConfigsAction = {
                                                type: '?',
                                                sourceConfigs: [],
                                                clientSideAccounts: []
                                              }) {

  const newState = {...state, ...DEFAULT_FLAGS};

  switch (action.type) {

    case ACTION_TYPES.SOURCE_CONFIG_GET:
      return {...newState, sourceConfigs: action.sourceConfigs};

    case ACTION_TYPES.SOURCE_CONFIG_SET:
      return {...newState, sourceConfigCreated: true};

    case ACTION_TYPES.SOURCE_CONFIG_UPDATE:
      return {...newState, sourceConfigUpdated: true, sourceConfig: action.sourceConfig};

    case ACTION_TYPES.SOURCE_CONFIG_DELETE:
      const sourceConfigsAfterDelete = state.sourceConfigs.filter((sourceConfig: SourceConfigModel) => {
        return sourceConfig.id !== action.sourceConfig.id;
      });
      return {...newState, sourceConfigs: sourceConfigsAfterDelete, sourceConfigDeleted: true};

    case ACTION_TYPES.SOURCE_CONFIG_GET_CLIENT_SIDE_ACCOUNTS:
      return {...newState, clientSideAccounts: action.clientSideAccounts};

    // error types are all handled in the same way
    case ACTION_TYPES.SOURCE_CONFIG_ERROR:
      return {...newState, error: action.error};

    case ACTION_TYPES.SOURCE_CONFIG_SET_HISTORY:
      return {...newState, history: action.history};

    default:
      return state;
  }
}

export const sourceConfigListSelector = (state) => state.sourceConfigurationsContainer.sourceConfigs;
export const sourceConfigErrorSelector = (state) => state.sourceConfigurationsContainer.error;
export const sourceConfigCreatedSelector = (state) => state.sourceConfigurationsContainer.sourceConfigCreated;
export const sourceConfigUpdatedSelector = (state) => state.sourceConfigurationsContainer.sourceConfigUpdated;
export const sourceConfigDeletedSelector = (state) => state.sourceConfigurationsContainer.sourceConfigDeleted;
export const sourceConfigClientSideAccountsSelector = (state) => state.sourceConfigurationsContainer.clientSideAccounts;
export const sourceConfigHistorySelector = (state) => state.sourceConfigurationsContainer.history;

