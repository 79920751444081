import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModel } from '../app.model';

/**
 * This class should be used to interact with http.
 * For handling results use #onSuccess and #onError. Use #doIt for executing the interaction.
 */
export class HttpInteraction {
  static readonly unknownError = 'An unknown error has occurred. Please consult the support if the error persists.';
  private successFunction;
  private errorFunction;

  constructor(private done) {
  }

  /**
   * Create a {ErrorModel} from given error.
   * @param error error response
   */
  static errorModel(error: HttpErrorResponse | any): ErrorModel {
    let status = 0;
    let rawErrorMessage = '';
    let message = '';


    if (error instanceof HttpErrorResponse) {
      let body = {error: '', metadata: null};
      try {
        body = error.error || {};
      } catch (e) {
      }
      rawErrorMessage = `${error.status} - ${error.statusText || ''}`;
      status = error.status;
      message = JSON.stringify(body);
      const errorMetaData = body.metadata;
      if (errorMetaData) {
        const curError = errorMetaData.error;

        /* istanbul ignore else */
        if (curError) {
          message = curError.message || HttpInteraction.unknownError;
        }
      }
    } else {
      rawErrorMessage = error.message || HttpInteraction.unknownError;
    }
    return new ErrorModel({status, statusText: rawErrorMessage, message});
  }

  onSuccess(successFunction: ((response) => void)): HttpInteraction {
    this.successFunction = successFunction;
    return this;
  }

  onError(errorFunction: ((error) => void)): HttpInteraction {
    this.errorFunction = errorFunction;
    return this;
  }

  doIt() {
    this.done();
  }

  success(success) {
    this.successFunction(success);
  }

  error(error) {
    /* istanbul ignore else */
    if (this.errorFunction) {
      this.errorFunction(error);
    }
  }

}

