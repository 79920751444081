import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ACTION_TYPES } from '../app.types';
import { FetchingActions } from '../fetching/fetching.actions';
import { AppStore } from '../store/store';
import { HttpInteraction } from './http-interactions.utils';

@Injectable({providedIn: 'root'})
export class HttpClientUtils {
  static JSON_UTF8 = 'application/json;charset=UTF-8';

  constructor(private http: HttpClient, private appStore: AppStore) {
  }

  getForJson(url, options = {}): HttpInteraction {
    /* tslint:disable */
    const headers = new HttpHeaders({
      'Content-Type': HttpClientUtils.JSON_UTF8,
      'Accept': HttpClientUtils.JSON_UTF8
    });
    /* tslint:enable */
    return this.get(url, {...options, headers});
  }

  get(url, options = {}): HttpInteraction {
    FetchingActions.actionFetchData(this.appStore);

    const observableResponse = this.http.get(url, options);

    const httpInteraction = new HttpInteraction(() => {
        observableResponse.subscribe(
          (response) => {
            httpInteraction.success(response);
          },
          (error) => {
            httpInteraction.error(error);
            this.handleError(error);
          });
      }
    );

    return httpInteraction;
  }

  post(url, data, options = {}): HttpInteraction {
    FetchingActions.actionFetchData(this.appStore);

    const observableResponse = this.http.post(url, data, options);
    const httpInteraction = new HttpInteraction(() => {
      observableResponse.subscribe(
        (response) => {
          httpInteraction.success(response);
        },
        (error) => {
          httpInteraction.error(error);
          this.handleError(error);
        });
    });
    return httpInteraction;
  }

  delete(url, options = {}): HttpInteraction {
    FetchingActions.actionFetchData(this.appStore);

    const observableResponse = this.http.delete(url, {...options, observe: 'response'});

    const httpInteraction = new HttpInteraction(() => {
      observableResponse.subscribe(
        (response: any) => {
          httpInteraction.success(response);
        },
        (error: any) => {
          httpInteraction.error(error);
        });
    });
    return httpInteraction;
  }

  put(url: string, data: any, options = {}): HttpInteraction {
    FetchingActions.actionFetchData(this.appStore);

    const observableResponse: Observable<any> = this.http.put(url, data, options);
    const httpInteraction = new HttpInteraction(() => {
      observableResponse.subscribe(
        (response) => {
          httpInteraction.success(response);
        },
        (error) => {
          httpInteraction.error(error);
          this.handleError(error);
        });
    });

    return httpInteraction;
  }

  handleError(error) {
    if (error.status === 401) {
      this.appStore.dispatch({type: ACTION_TYPES.USER_CHANGED_UNAUTHORIZED});
    }
  }

}
