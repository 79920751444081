export const ACTION_TYPES = {

  USER_GET: 'USER_GET',
  USER_SET: 'USER_SET',
  USER_CLEAR: 'USER_CLEAR',
  USER_ERROR: 'USER_ERROR',
  USER_CHANGED_UNAUTHORIZED: 'USER_CHANGED_UNAUTHORIZED',
  USER_RESTORE: 'USER_RESTORE',

  SEGMENT_GET: 'SEGMENT_GET',
  SEGMENT_LIST_GET: 'SEGMENT_LIST_GET',
  SEGMENT_LIST_GET_FOR_VALIDATION: 'SEGMENT_LIST_GET_FOR_VALIDATION',
  SEGMENT_SET: 'SEGMENT_SET',
  SEGMENT_CREATED_CLEAR: 'SEGMENT_CREATED_CLEAR ',
  SEGMENT_ERROR: 'SEGMENT_ERROR',
  SEGMENT_DELETE_INIT: 'SEGMENT_DELETE_INIT',
  SEGMENT_DELETE: 'SEGMENT_DELETE',
  SEGMENT_SELECTED: 'SEGMENT_SELECTED',
  SEGMENT_UPDATE: 'SEGMENT_UPDATE',

  SEGMENT_TOGGLE_FILTER: 'SEGMENT_TOGGLE_FILTER',
  SEGMENT_FILTER_ACCOUNTS_GET: 'SEGMENT_FILTER_ACCOUNTS_GET',
  SEGMENT_FILTER_CHANGE: 'SEGMENT_FILTER_CHANGE',

  TARGET_LIST_GET: 'TARGET_LIST_GET',
  TARGET_GET_SEGMENT: 'TARGET_GET_SEGMENT',
  TARGET_CLEAR: 'TARGET_CLEAR',
  TARGET_ERROR: 'TARGET_ERROR',
  TARGETS_SHARED_FOR_SEGMENT: 'TARGETS_SHARED_FOR_SEGMENT',
  TARGET_UNSHARE_SEGMENT: 'TARGET_UNSHARE_SEGMENT',

  PARTNER_LIST_GET: 'PARTNER_LIST_GET',
  PARTNER_LIST_GET_ERROR: 'PARTNER_LIST_GET_ERROR',

  NOTIFICATION_CLEAR: 'NOTIFICATION_CLEAR',

  FETCHING_DATA: 'FETCHING_DATA',

  SEGMENT_VALIDATION_LIST_GET: 'SEGMENT_VALIDATION_LIST_GET',
  SEGMENT_VALIDATION_ERROR: 'SEGMENT_VALIDATION_ERROR',
  SEGMENT_VALIDATION_SET: 'SEGMENT_VALIDATION_SET',
  SEGMENT_VALIDATION_DELETE: 'SEGMENT_VALIDATION_DELETE',
  SEGMENT_VALIDATION_SELECTED: 'SEGMENT_VALIDATION_SELECTED',
  SEGMENT_VALIDATION_UPDATE: 'SEGMENT_VALIDATION_UPDATE',
  SEGMENT_VALIDATION_INIT_DIALOG: 'SEGMENT_VALIDATION_INIT_DIALOG',

  SOURCE_LIST_GET: 'SOURCE_LIST_GET',
  SOURCE_SET: 'SOURCE_SET',
  SOURCE_ERROR: 'SOURCE_ERROR',
  SOURCE_UPDATE: 'SOURCE_UPDATE',
  SOURCE_SELECTED: 'SOURCE_SELECTED',
  SOURCE_GET: 'SOURCE_GET',

  SOURCE_CONFIG_LIST_GET: 'SOURCE_CONFIG_LIST_GET',
  SOURCE_CONFIG_SET: 'SOURCE_CONFIG_SET',
  SOURCE_CONFIG_ERROR: 'SOURCE_CONFIG_ERROR',
  SOURCE_CONFIG_UPDATE: 'SOURCE_CONFIG_UPDATE',
  SOURCE_CONFIG_ALL: 'SOURCE_CONFIG_ALL',
  SOURCE_CONFIG_SELECTED: 'SOURCE_CONFIG_SELECTED',
  SOURCE_CONFIG_GET: 'SOURCE_CONFIG_GET',
  SOURCE_CONFIG_DELETE: 'SOURCE_CONFIG_DELETE',
  SOURCE_CONFIG_GET_CLIENT_SIDE_ACCOUNTS: 'SOURCE_CONFIG_GET_CLIENT_SIDE_ACCOUNTS',
  SOURCE_CONFIG_SET_HISTORY: 'SOURCE_CONFIG_SET_HISTORY',

  SHARED_CONFIG_GET_ALL: 'SHARED_CONFIG_GET_ALL',
  SHARED_CONFIG_ERROR: 'SHARED_CONFIG_ERROR',
  SHARED_CONFIG_ALL: 'SHARED_CONFIG_ALL',
  SHARED_CONFIG_SELECTED: 'SHARED_CONFIG_SELECTED',
  SHARED_CONFIG_GET: 'SHARED_CONFIG_GET',

  TARGET_SYSTEM_LIST_GET: 'TARGET_SYSTEM_LIST_GET',
  DISTRIBUTION_ACCOUNT_LIST_SET: 'DISTRIBUTION_ACCOUNT_LIST_SET',
  DISTRIBUTION_ACCOUNT_LIST_UPDATE: 'DISTRIBUTION_ACCOUNT_LIST_UPDATE',
  DISTRIBUTION_ACCOUNT_LIST_GET: 'DISTRIBUTION_ACCOUNT_LIST_GET',
  DISTRIBUTION_ACCOUNT_ERROR: 'DISTRIBUTION_ACCOUNT_ERROR'
};
