import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ErrorModel } from '../../../app.model';
import { AppStore } from '../../../store/store';
import { SourceModel } from '../../source.model';
import { sourceSelector } from '../../source.reducer';
import { SourceConfigModel } from '../config.model';
import { sourceConfigDeletedSelector, sourceConfigErrorSelector } from '../config.reducer';
import { SourceConfigService } from '../config.service';

@Component({
  selector: 'app-segment-delete',
  template: `
<div class="dialog-container">
  <button mat-icon-button id="dialog-close-button" mat-dialog-close>
    <mat-icon>clear</mat-icon>
  </button>
  <h5 mat-dialog-title>Delete Source Config "{{currentSourceConfig.id}}"?</h5>
  <div mat-dialog-content>
    <p [hidden]="!errorMessage" id="error-feedback" class="error">{{errorMessage}}</p>
    <p class="dialog-message">Do you really want to delete this source config?</p>
  </div>
  <div mat-dialog-actions>
    <button name="cancel" id="button-delete-cancel" color="primary" mat-raised-button mat-dialog-close>Cancel</button>
    <button name="ok" id="button-delete-ok" color="warn" mat-raised-button (click)="delete()">Delete</button>
  </div>
</div>
`
})
export class SourceConfigDeleteComponent implements OnInit, OnDestroy {

  currentSource: SourceModel;
  currentSourceConfig: SourceConfigModel;
  unsubscribe;
  errorMessage;

  constructor(private appStore: AppStore,
              private dialogRef: MatDialogRef<SourceConfigDeleteComponent>,
              private sourceConfigsService: SourceConfigService) {
  }

  ngOnInit(): void {
    this.clearErrorMessage();
    const appState = this.appStore.getState();
    this.currentSource = sourceSelector(appState);
    /* istanbul ignore else */
    if (!this.currentSource) {
      throw new Error('A source must be selected for creating config!');
    }

    this.unsubscribe = this.appStore.subscribe((state) => {
      this.clearErrorMessage();

      /* istanbul ignore else */
      if (sourceConfigDeletedSelector(state)) {
        this.dialogRef.close();
      }

      const error: ErrorModel = sourceConfigErrorSelector(state);
      /* istanbul ignore else */
      if (error) {
        this.errorMessage = 'Delete failed';
        /* istanbul ignore else */
        if (error.status === 400) {
          this.errorMessage = error.message;
        }
        /* istanbul ignore else */
        if (error.status === 401) {
          this.errorMessage = 'Delete not allowed';
        }
      }

    });
  }

  ngOnDestroy() {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  delete() {
    this.sourceConfigsService.deleteSourceConfig(this.currentSource, this.currentSourceConfig);
  }

  private clearErrorMessage() {
    this.errorMessage = null;
  }

}
