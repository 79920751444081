import { Injectable } from '@angular/core';
import { ACTION_TYPES } from '../app.types';
import { AppStore } from '../store/store';
import { UserModel } from './user.model';

export interface UserAction {
  type: string;
  user?: UserModel;
  error?: string;
}

@Injectable()
export class UserActions {

  constructor(public appStore: AppStore) {
  }

  static userSetLocalStorage(user: UserModel) {
    /* istanbul ignore else */
    if (user.username && user.username.indexOf('emetriq.com') && !user.roles.includes('admin')) {
      user.roles.push('admin');
    }
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  static userGetLocalStorage(): UserModel {
    return JSON.parse(localStorage.getItem('currentUser')) || {} as UserModel;
  }

  static error(error) {
    return {type: ACTION_TYPES.USER_ERROR, error};
  }
}
