import { ErrorModel } from '../app.model';
import { ACTION_TYPES } from '../app.types';
import { SourcesAction } from './source.actions';
import { SourceModel } from './source.model';

const DEFAULT_FLAGS = {
  error: null,
  sourceCreated: false,
  sourceUpdated: false
};

const INITIAL_STATE = {
  source: null,
  sources: [],
  ...DEFAULT_FLAGS
};

export function sourceContainer(state = INITIAL_STATE, action: SourcesAction = {type: '?'}) {

  const newState = {...state, ...DEFAULT_FLAGS};

  switch (action.type) {

    case ACTION_TYPES.SOURCE_LIST_GET:
      return {...newState, sources: action.sources};

    case ACTION_TYPES.SOURCE_SET:
      return {...newState, sourceCreated: true};

    case ACTION_TYPES.SOURCE_GET:
      return {...newState, source: action.source};

    case ACTION_TYPES.SOURCE_UPDATE:
      const sourcesAfterUpdate = state.sources.map((item: SourceModel) => {
        return item.id === action.source.id ? action.source : item;
      });

      return {...newState, sources: sourcesAfterUpdate, sourceUpdated: true, source: action.source};

    case ACTION_TYPES.SOURCE_SELECTED:
      return {...newState, source: action.source};

    case ACTION_TYPES.SOURCE_ERROR:
      return {...newState, error: action.error};

    default:
      return state;
  }
}

export const sourceListSelector = (state): SourceModel[] => state.sourceContainer.sources;
export const sourceErrorSelector = (state): ErrorModel => state.sourceContainer.error;
/** get current source from store. */
export const sourceSelector = (state): SourceModel => state.sourceContainer.source;
export const sourceCreatedSelector = (state): boolean => state.sourceContainer.sourceCreated;
export const sourceUpdatedSelector = (state): boolean => state.sourceContainer.sourceUpdated;

