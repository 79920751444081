import { RouterModule, Routes } from '@angular/router';
import { DocumentationComponent } from './documentation/documentation.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { SharedConfigComponent } from './shared-config/shared-config.component';
import { SigninComponent } from './signin/signin.component';
import { SourceConfigHistoryComponent } from './source/config/history/history.component';
import { SourceConfigsListComponent } from './source/config/list/list.component';
import { SourceComponent } from './source/source.component';

export const routes: Routes = [
  {path: '', component: SourceComponent, canActivate: [AuthGuardService]},
  {path: 'signin', component: SigninComponent},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuardService]},
  {
    path: 'sources', component: SourceComponent, canActivate: [AuthGuardService], children: [
      {
        path: '',
        component: SourceConfigsListComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'history',
        component: SourceConfigHistoryComponent,
        canActivate: [AuthGuardService]
      }
    ]
  },
  {path: 'shared', component: SharedConfigComponent, canActivate: [AuthGuardService]},
  {path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService]},
  {path: 'docs', component: DocumentationComponent}
];

export const ROUTES = RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'});
