import { DistributionAccountModel } from '../app.model';

export const RENDER_TYPES = {
  JAVASCRIPT: 'JAVASCRIPT',
  IMAGEPIXEL: 'IMAGEPIXEL',
  COOKIE: 'COOKIE'
};

export class SharedConfigModel {

  static JAVASCRIPT = 'JAVASCRIPT';
  static IMAGEPIXEL = 'IMAGEPIXEL';
  static COOKIE = 'COOKIE';

  id?: number;
  type?: string;
  name: string;
  description?: string;
  deliverCondition?: string;
  distributionAccountId?: string;
  distributionAccount?: DistributionAccountModel;
  deliverThrottle = 0.0;
  ignorePeriodDays = 0;
  elementSource?: string;
  fireForKnownUsersOnly?: boolean;

  constructor(fields: {
    id?: number,
    type: string,
    name: string,
    description?: string,
    deliverCondition?: string,
    distributionAccountId?: string,
    distributionAccount?: DistributionAccountModel,
    deliverThrottle?: number,
    ignorePeriodDays?: number,
    elementSource?: string,
    fireForKnownUsersOnly?: boolean
  }) {
    if (fields) {
      Object.assign(this, fields);
      if (this.distributionAccount) {
        this.distributionAccount = DistributionAccountModel.from(this.distributionAccount);
      }
    }
  }

  static from(obj): SharedConfigModel {
    return new SharedConfigModel(obj);
  }

  static fromList(list: any[]): SharedConfigModel[] {
    return list.map((it) => {
      return SharedConfigModel.from(it);
    });
  }
}
