import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppStore } from '../../store/store';
import { SourceModel } from '../source.model';
import { sourceCreatedSelector, sourceErrorSelector } from '../source.reducer';
import { SourceService } from '../source.service';

@Component({
  selector: 'app-source-create',
  templateUrl: 'create.component.html'
})
export class SourceCreateComponent implements OnInit, OnDestroy {
  errorMessage: string;
  unsubscribe;
  sourceCreateForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<SourceCreateComponent>,
              private appStore: AppStore,
              private sourceService: SourceService) {
  }

  ngOnInit(): void {
    this.sourceCreateForm = new FormGroup({
      name: new FormControl('', Validators.required)
    });

    this.unsubscribe = this.appStore.subscribe((state) => {
      if (sourceCreatedSelector(state)) {
        this.dialogRef.close();
      } else {
        const sourceCreateErrorState = sourceErrorSelector(state);
        /* istanbul ignore else */
        if (sourceCreateErrorState) {
          this.errorMessage = 'Creating new source failed! ' + sourceCreateErrorState.statusText;
        }
      }
    });
  }

  ngOnDestroy(): void {
    /* istanbul ignore else */
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  reset() {
    this.resetError();
    this.sourceCreateForm.reset();
  }

  create(): void {
    this.resetError();
    this.sourceService.createSource(new SourceModel(this.sourceCreateForm.value));
  }

  private resetError() {
    this.errorMessage = '';
  }
}
