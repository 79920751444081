import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-input-dropdown',
  template: `
    <mat-form-field>
      <input [formControl]="inputControl"
             [matAutocomplete]="auto"
             matInput
             #inputTag
             name="{{elementId}}"
             placeholder="{{placeholder}}">
      <mat-autocomplete (optionSelected)="valueChanged($event)" #auto="matAutocomplete" [displayWith]="createDisplayFn">
        <mat-option *ngFor="let element of filteredData | async | orderBy: orderByValue"
                    [value]="element.id">
          {{element.id}} - {{element.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="inputControl.hasError('required')">
        Missing {{label}}
      </mat-error>
    </mat-form-field>
  `
})
export class DropdownComponent<T> implements OnInit, OnChanges {
  @Input() elementId: string;
  @Input() placeholder = 'Type to search';
  @Input() data: any[];
  @Input() orderByValue: string;
  @Input() label;

  @Output() valueChange: EventEmitter<T> = new EventEmitter();

  @Input() inputControl: FormControl;
  filteredData: Observable<T[]>;

  createDisplayFn = (id) => {
    if (id) {
      const selectedData = this.data.find((element) => {
        return element.id === id;
      });
      return selectedData ? selectedData.id + ' - ' + selectedData.name : null;
    }
  }

  ngOnInit() {
    this.filteredData = this.getFilteredData();
  }

  ngOnChanges(changes: SimpleChanges) {
    /* istanbul ignore else */
    if (changes.data) {
      this.filteredData = this.getFilteredData();
    }
  }

  getFilteredData(): Observable<T[]> {
    return this.inputControl.valueChanges
      .pipe(
        startWith(''),
        map((formInputValue) => {
          return formInputValue && typeof formInputValue === 'object' ? this.createDisplayFn(formInputValue.id) : formInputValue;
        }),
        map((val) => {
          return val ? this._filter(val) : this.data.slice();
        })
      );
  }

  valueChanged(event) {
    this.valueChange.emit(event.value);
  }

  private _filter(val) {
    return this.data.filter((option) => {
      return new RegExp(val, 'gi').test(this.createDisplayFn(option.id));
    });
  }
}
