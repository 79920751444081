import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatCodemirrorModule } from 'ngx-mat-codemirror';
import { DropdownComponent } from '../components/dropdown/dropdown.component';
import { FormattedCodeComponent } from '../components/formattedcode/formatted-code.component';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { MaterialModule } from './material.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  declarations: [
    OrderByPipe,
    FormattedCodeComponent,
    DropdownComponent
  ],
  exports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OrderByPipe,
    FormattedCodeComponent,
    MatCodemirrorModule,
    DropdownComponent
  ]
})
export class SharedModule {
}
