import { ErrorModel } from '../app.model';
import { ACTION_TYPES } from '../app.types';
import { SourceConfigModel } from '../source/config/config.model';
import { SharedConfigAction } from './shared-config.actions';

const DEFAULT_FLAGS = {
  error: null,
  sharedConfigCreated: false,
  sharedConfigUpdated: false,
  sharedConfigDeleted: false
};

export const INITIAL_STATE = {
  sharedConfigs: [],
  clientSideAccounts: [],
  history: [],
  ...DEFAULT_FLAGS
};

export function sharedConfigurationsContainer(state = INITIAL_STATE, action: SharedConfigAction = {
  type: '?',
  sharedConfigs: [],
  clientSideAccounts: []
}) {

  const newState = {...state, ...DEFAULT_FLAGS};

  switch (action.type) {

    case ACTION_TYPES.SHARED_CONFIG_GET:
      return {...newState, sharedConfigs: action.sharedConfigs};

    case ACTION_TYPES.SHARED_CONFIG_ERROR:
      return {...newState, error: action.error};

    default:
      return state;
  }
}

export const sharedConfigListReducer = (state): SourceConfigModel[] => state.sharedConfigurationsContainer.sharedConfigs;
export const sharedConfigErrorSelector = (state): ErrorModel => state.sharedConfigurationsContainer.error;
