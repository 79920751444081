export interface BackendResponse {
  data: any;
}

export class ErrorModel {

  status: number;
  statusText: string;
  message: string;

  constructor(fields: { status?: number, statusText?: string, message?: string }) {
    /* istanbul ignore else */
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class DistributionAccountModel {
  id: number;
  foreignAccountId: string;
  description: string;

  constructor(fields?: {
    id: number,
    foreignAccountId?: string,
    description: string,
    accountId?: string,
  }) {
    if (fields) {
      // we need to rename accountId to foreignAccountId
      if (typeof fields.accountId !== 'undefined') {
        Object.defineProperty(fields, 'foreignAccountId', Object.getOwnPropertyDescriptor(fields, 'accountId'));
        delete fields.accountId;
      }
      Object.assign(this, fields);
    }
  }

  static from(object: any): DistributionAccountModel {
    return new DistributionAccountModel(object);
  }

  static fromList(objects: any[]): DistributionAccountModel[] {
    return objects.map((item) => {
      return DistributionAccountModel.from(item);
    });
  }
}
