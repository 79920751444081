import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment, HTTP_INTERCEPORTS } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { ROUTES } from './app.routes';
import { DocumentationModule } from './documentation/documentation.module';
import { AuthGuardService } from './guards/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { SharedModule } from './modules/shared.module';
import { NotificationComponent } from './notification/notfication.component';
import { ProgressComponent } from './progress/progress.component';
import { SharedConfigModule } from './shared-config/shared-config.module';
import { SigninComponent } from './signin/signin.component';
import { SourceModule } from './source/source.module';
import { DmpStoreModule } from './store/store';
import { SwUpdateComponent } from './sw-update/sw-update.component';
import { UserActions } from './user/user.actions';
import { UserService } from './user/user.service';
import { AuthInterceptor, PartnerInterceptor } from './utils/auth-interceptor';
import { HttpClientUtils } from './utils/http-client.utils';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AuthGuardService,
  UserService,
  HttpClientUtils,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PartnerInterceptor,
    multi: true
  }
];

/** Redux Actions. */
const APP_REDUX_ACTIONS = [
  UserActions
];

@NgModule({
  declarations: [
    ProgressComponent,
    AppComponent,
    HomeComponent,
    NotificationComponent,
    LogoutComponent,
    SigninComponent,
    SwUpdateComponent
  ],
  imports: [
    BrowserModule,
    // (environment.env !== 'e2e' ? BrowserAnimationsModule : NoopAnimationsModule), // https://github.com/angular/angular/issues/36243
    BrowserAnimationsModule,
    DmpStoreModule,
    HttpClientModule,
    SharedModule,
    SourceModule,
    SharedConfigModule,
    DocumentationModule,
    ROUTES,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    APP_PROVIDERS,
    APP_REDUX_ACTIONS,
    HTTP_INTERCEPORTS
  ],
  entryComponents: [],
  exports: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
