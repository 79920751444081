import { NgModule } from '@angular/core';
import { MatCodemirrorComponent } from './mat-codemirror.component';

@NgModule({
  declarations: [
    MatCodemirrorComponent,
  ],
  exports: [
    MatCodemirrorComponent,
  ]
})
export class MatCodemirrorModule {
}
