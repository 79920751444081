import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { AppStore } from '../store/store';
import { userSelector } from '../user/user.reducer';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return new Observable((subscriber) => {

      let authReq: HttpRequest<any> = request;

      this.authService.session({

        onSuccess: (session) => {

          authReq = request.clone({
            setHeaders: {
              Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
            }
          });

          next.handle(authReq).pipe(
            finalize(() => {
              subscriber.complete();
            }))
            .subscribe(
              httpEvent => subscriber.next(httpEvent),
              err => subscriber.error(err)
            );
        },

        onFailure: (err) => {
          console.log('Auth error', err);
          subscriber.error(err);
        }
      });
    });

  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    let authReq = req;

    await this.authService.session({
      onSuccess: (session) => {
        authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
          }
        });
      },
      onFailure: (err) => console.log('Auth error', err)
    });

    return next.handle(authReq).toPromise();
  }
}

@Injectable()
export class PartnerInterceptor implements HttpInterceptor {

  private user$;

  constructor(private appStore: AppStore) {
    this.user$ = userSelector(this.appStore.getState());
    this.appStore.subscribe((state) => {
      this.user$ = userSelector(state);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET' && this.user$ && this.user$.partnerId) {
      request = request.clone({
        setParams: {
          partnerId: `${this.user$.partnerId}`
        }
      });
    }

    return next.handle(request);
  }
}
