import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SourceComponent } from '../../../source.component';
import { SourceConfigVersionModel } from '../../config-version.model';

@Component({
  selector: 'app-source-configs-history-select',
  templateUrl: 'select.component.html',
  styles: [
    `.mat-select {
      width: 180px;
      margin: 0 10px;
    }`
  ]
})
export class SourceConfigsHistorySelectComponent {
  @Input() histories: SourceConfigVersionModel[];
  @Output() selectAction: EventEmitter<number> = new EventEmitter<number>();

  dateTimeFormat = SourceComponent.dateTimeFormat;

  selectedIndex = 0;

  select(index) {
    this.selectedIndex = index;
    this.selectAction.emit(this.selectedIndex);
  }

  next() {
    /* istanbul ignore else */
    if (this.hasNext()) {
      this.selectedIndex--;
      this.select(this.selectedIndex);
    }
  }

  previous() {
    /* istanbul ignore else */
    if (this.hasPrevious()) {
      this.selectedIndex++;
      this.select(this.selectedIndex);
    }
  }

  hasPrevious() {
    return this.selectedIndex < (this.histories.length - 1);
  }

  hasNext() {
    return this.selectedIndex > 0;
  }
}
