import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ACTION_TYPES} from '../app.types';
import {notify} from '../notification/notfication.actions';
import {AppStore} from '../store/store';
import {HttpClientUtils} from '../utils/http-client.utils';
import {HttpInteraction} from '../utils/http-interactions.utils';
import {SourceActions} from './source.actions';
import {SourceModel} from './source.model';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class SourceService {

  constructor(public appStore: AppStore, private httpClientUtils: HttpClientUtils, private httpClient: HttpClient) {
  }

  /** NO BFF */
  getAllSources() {
    this.httpClientUtils.getForJson(`${environment.apiSourceConfigs}/sources`)
      .onSuccess((response) => {
        const sourcesModels = SourceModel.fromList(response.data || response as SourceModel[]);
        SourceActions.dispatchGetAll(this.appStore, sourcesModels);
      })
      .onError((error) => {
        this.appStore.dispatch({type: ACTION_TYPES.SOURCE_LIST_GET, error: HttpInteraction.errorModel(error)});
      })
      .doIt();
  }

  /** NO BFF */
  createSource(source: SourceModel) {
    this.httpClientUtils.post(`${environment.apiSourceConfigs}/sources`, source)
      .onSuccess(() => {
        notify(this.appStore, `${source.name} created.`);
        SourceActions.dispatchCreateSource(this.appStore);
        this.getAllSources();
      })
      .onError((error) => {
        SourceActions.dispatchCreateSourceError(HttpInteraction.errorModel(error), this.appStore);
      })
      .doIt();
  }

  /** NO BFF */
  updateSource(sourceModel: SourceModel) {
    this.httpClientUtils.put(`${environment.apiSourceConfigs}/sources`, sourceModel)
      .onSuccess(() => {
        notify(this.appStore, `${sourceModel.name} updated.`);
        this.appStore.dispatch({type: ACTION_TYPES.SOURCE_UPDATE, source: sourceModel});
      })
      .onError((error) => {
        this.appStore.dispatch({
          type: ACTION_TYPES.SOURCE_ERROR,
          error: HttpInteraction.errorModel(error)
        });
      })
      .doIt();
  }

  updateSourceCrttEnabled(id: number, crttEnabled: boolean): Observable<unknown | HttpErrorResponse> {
    const url = `${environment.apiSourceConfigs}/sources/${id}/crttenabled`;
    const data = { crttEnabled };
    return this.httpClient.put(url, data);
  }

  selectSource(sourceModel: SourceModel) {
    SourceActions.dispatchSelectSource(sourceModel, this.appStore);
  }
}
