import { Component } from '@angular/core';

const esprima = require('esprima');

@Component({
  selector: 'app-sources',
  templateUrl: 'source.component.html'
})
export class SourceComponent {
  static dateTimeFormat = 'dd.MM.yyyy HH:mm:ss';
}
