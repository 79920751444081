import { Injectable } from '@angular/core';
import { DistributionAccountModel, ErrorModel } from '../app.model';
import { ACTION_TYPES } from '../app.types';
import { AppStore } from '../store/store';
import { SharedConfigVersionModel } from './shared-config-version.model';
import { SharedConfigModel } from './shared-config.model';

export interface SharedConfigAction {
  type: string;
  sharedConfig?: SharedConfigModel;
  sharedConfigs?: SharedConfigModel[];
  clientSideAccounts?: DistributionAccountModel[];
  history?: SharedConfigVersionModel[];
  error?: ErrorModel;
}

@Injectable()
export class SharedConfigActions {

  static dispatchGetSharedConfigs(sharedConfigModels: SharedConfigModel[], appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SHARED_CONFIG_GET, sharedConfigs: sharedConfigModels});
  }

  static dispatchSharedConfigError(errorModel: ErrorModel, appStore: AppStore) {
    appStore.dispatch({type: ACTION_TYPES.SHARED_CONFIG_ERROR, error: errorModel});
  }

}
