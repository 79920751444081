import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VendorModel } from './vendor.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private httpClient: HttpClient) {
  }

  getVendors(): Observable<VendorModel[]> {
    return this.httpClient.get<VendorModel[]>(`${environment.apiSourceConfigs}/vendors`);
  }
}
