import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppStore } from '../store/store';
import { HttpClientUtils } from '../utils/http-client.utils';
import { HttpInteraction } from '../utils/http-interactions.utils';
import { SharedConfigActions } from './shared-config.actions';
import { SharedConfigModel } from './shared-config.model';

@Injectable()
export class SharedConfigService {

  constructor(public appStore: AppStore, protected httpClient: HttpClientUtils) {
  }

  getSharedConfigs() {
    this.httpClient.getForJson(`${environment.apiSourceConfigs}/sources/shared-configs`)
      .onSuccess((response) => {
        const sourceConfigModels = SharedConfigModel.fromList(response.data || response || []);
        SharedConfigActions.dispatchGetSharedConfigs(sourceConfigModels, this.appStore);
      })
      .onError((error) => {
        SharedConfigActions.dispatchSharedConfigError(HttpInteraction.errorModel(error), this.appStore);
      })
      .doIt();
  }
}
