import { fetchingContainer } from './fetching/fetching.reducer';
import { notificationContainer } from './notification/notfication.reducer';
import { sharedConfigurationsContainer } from './shared-config/shared-config.reducer';
import { sourceConfigurationsContainer } from './source/config/config.reducer';
import { sourceContainer } from './source/source.reducer';
import { userContainer } from './user/user.reducer';

export const reducers = {
  userContainer,
  notificationContainer,
  fetchingContainer,
  sourceContainer,
  sourceConfigurationsContainer,
  sharedConfigurationsContainer
};

