import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private user: UserService) {
  }

  canActivate(): Observable<boolean> {
    return this.user.checkCredentials();
  }

}
