import {Injectable, NgModule} from '@angular/core';
import {Action, ActionReducer, MetaReducer, Store, StoreModule} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {reducers} from '../app.reducers';

/* istanbul ignore next */
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    const stateClone = Object.assign({}, state);
    const stateAfter = reducer(state, action);
    // console.log('📦 NgRx/Store :: Action:', action, ' :: StateBefore:', stateClone, ' :: StateAfter:', stateAfter);
    return stateAfter;
  };
}

/* istanbul ignore next */
export const metaReducers: MetaReducer[] = [debug];

/**
 * This class wraps the old AppStore to be able to migrate smoother to the new one.
 *
 * Step 1 is to completely use this wrapper!
 */
@Injectable({providedIn: 'root'})
export class AppStore {

  private currentState: any;

  constructor(private store: Store<any>) {
    this.store.subscribe((state) => this.currentState = state);
  }

  /**
   * Get current state
   */
  getState(): any {
    return this.currentState;
  }

  /**
   * subscribe to a callback with the state
   */
  subscribe(subscriber): () => void {
    const subscription = this.store.subscribe(subscriber);
    return () => {
      subscription.unsubscribe();
    };
  }

  /**
   * Dispatch an action
   */
  dispatch(action: Action | any): void {
    this.store.dispatch(action);
  }

}

// New in 9: https://v9.ngrx.io/guide/store/configuration/runtime-checks
export const NGRX_RUNTIME_CHECKS = {
  strictStateSerializability: false,
  strictActionSerializability: false,
  strictStateImmutability: false,
  strictActionImmutability: false,
  strictActionWithinNgZone: false,
  strictActionTypeUniqueness: true,
};

@NgModule({
  providers: [],
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers: environment.production ? [] : metaReducers,
      runtimeChecks: NGRX_RUNTIME_CHECKS,
    })
  ],
})
export class DmpStoreModule {
}
