import {Component, OnInit} from '@angular/core';
import {AppStore} from '../../store/store';
import {sourceSelector} from '../source.reducer';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SourceModel} from '../source.model';
import {NotificationService} from '../../utils/notification-service';
import {SourceService} from '../source.service';

@Component({
  selector: 'app-crtt-config',
  templateUrl: './crtt-config.component.html',
  styleUrls: ['./crtt-config.component.scss']
})
export class CrttConfigComponent implements OnInit {
  source: SourceModel;

  constructor(private appStore: AppStore,
              private sourceService: SourceService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.appStore.subscribe((state) => {
      const source = sourceSelector(state);
      if (source) {
        this.source = sourceSelector(state);
      }
    });
  }

  display(): boolean {
    return (this.source && this.source.id > 0);
  }

  crttEnabledUpdated(event: MatCheckboxChange): void {
    this.sourceService.updateSourceCrttEnabled(this.source.id, event.checked).subscribe(
      () => {
        const status = event.checked ? 'enabled' : 'disabled';
        this.notificationService.success(`Source ${this.source.sourceId} is now ${status} for CRTT`);
        this.source.crttEnabled = event.checked;
      }, error => {
        this.notificationService.success(`Error occured trying to set CRTT for source ${this.source.sourceId}`);
        event.source.checked = !event.checked;
      }
    );
  }
}
